import styled from "styled-components";


export const McqTestingPageWrapper = styled.div`
.list{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.mcqCard{
    border: 1px solid #b7b6b6;
    padding: 0% 2%;
    border-radius: 10px;
}
.questionAndLevel{
    display: flex;
    justify-content: space-between;
}
.question{
    font-weight: 600;
    font-size: 14px;
    color:#213861;
}
.options{
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-left: 4px;

}
.options span{
    font-size: 12px;
    font-weight: 500;
}
.correctAnswer{
    font-size: 14px;
    color: green;
    font-weight: 600;
}
.explanation{
    font-size: 14px;
    color: #213861;
    font-weight: 600;
}
.hint{
    border: 1px solid #9f9f9f;
    margin: 1% 0;
    padding: 1%;
    border-radius: 10px;
    font-size: 15px;
}
.question-image_img{
    max-width:100%;
    max-height:300px;
}
`;