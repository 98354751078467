import React, { useState, useEffect } from 'react'
import { SubmitMcqProgramWrapper, SubmitMissionProgramWrapper } from './SubmitMcqInstance.style';
import { Breadcrumb, Button, Input, Modal, Select, message } from 'antd';
import { useLocation, useNavigate, } from 'react-router-dom';
import Spinner from "assets/images/Spinner.gif";
import SecuredServices from 'api/securedServices';
import GenricModal from 'components/comman/modalComponents/modal/modal';

export default function SubmitMcqProgram() {


  const location = useLocation();
  const Navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const attachments = location.state;
  const isRevert = params.get('is_revert');
  const qName = params.get('q_name');
  let topicId = params.get('topic_id');
  // const conceptId = params.get('concept_id');
  let topic_name = params.get('topic_name');
  const grade_id = params.get('grade_id');
  const [userInputData, setUserInputData] = useState([])
  const [selectedTopic, setSelectedTopic] = useState({});
  // const [missionPdfModal, setMissionPdfModal] = useState(false);
  const [blockSubmitAndTest, setBlockSubmitAndTest] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [concepts, setConcepts] = useState([]);
  const [topics, setTopics] = useState([]);
  const [topicDisabled, setTopicDisabled] = useState(true);
  const [testMcqModal, setTestMcqModal] = useState(false);
  const [shouldUploadMcqImage,setShouldUploadMcqImage] = useState(false);
  const [orignalImagesPopup,setOriginalImagesPopup] = useState(false);
  const [selectedQname,setSelectedQName] = useState();
  const [selectedQnameIndex,setSelectedQnameIndex]= useState();
  

  useEffect(() => {
    SecuredServices.getMcqDetailsByQName(qName).then((res) => {
      console.log(res);
      if (res.data.status == 1) {
        let data = res.data.data;
        // data.mcq_json = data.mcq_json.map((item)=>{
        //   return JSON.stringify(item);
        // })
        console.log(data);
        setUserInputData(res.data.data);
      }
    })
  }, [])

  const handleTopicChange = (value) => {
    setSelectedTopic({
      topicId: value,
      topic_name: topics.find(topic => topic.topic_id === value)?.name
    })
    // setUserInputData({...userInputData,topic_id:value,topic_name:topics.find(topic=>topic.topic_id===value)?.name,concept_id:-1,concept_name:""});
  }


  useEffect(() => {

    SecuredServices.getTopics(grade_id,1).then((res) => {
      console.log(res.data.data);
      let topics = res.data.data.data;
      setTopics(topics);
    })
  }, [])

  useEffect(() => {
    setSelectedTopic({
      topicId: topicId,
      topic_name: topic_name,
    })
  }, [topicId, topic_name])

  // useEffect(() => {
  //   if (selectedTopic.topicId && selectedTopic.topicId != -1) {
  //     SecuredServices.getMcqConcepts(selectedTopic.topicId).then((res) => {
  //       console.log(res.data.data);
  //       setConcepts(res.data.data.data.map((item) => {
  //           return {
  //             concept_id:item.mconcept_id,
  //             concept_name:item.name
  //           }
  //       }))
  //           })
  //   }

  // }, [selectedTopic])

  const handleSubmit = (isSubmit) => {

    // if(isSubmit==false){
    //   setBlockSubmitAndTest(true);
    // }


    console.log(userInputData);

    setBlockSubmitAndTest(true);
    setDisableSubmit(true);

    let data = userInputData;
    let flag = 1;
    data?.forEach((item, index) => {
      try {
        if (item.mcq_json == "") {
          message.error("Please enter valid mcq json for question " + (index + 1));

          flag = 0;
        }
      } catch (error) {
        message.error("Please enter valid mcq json for question " + (index + 1));
        flag = 0;
      }
    })


    if (!isSubmit && flag == 1) {
      setTestMcqModal(true);
      setBlockSubmitAndTest(false);
      setDisableSubmit(false);
    }

    else if (flag == 1) {
      console.log(data);

      let submitdata = {
        q_name_original: qName,
        topic_id: selectedTopic.topicId,
        topic_name: selectedTopic.topic_name,
        mcqs: data,
      }
      console.log(submitdata);
      SecuredServices.postSubmitMcqInstances(submitdata).then((res) => {
        if (res.data.status == 1) {
          message.success(res.data.message);
          setTestMcqModal(false);
          setBlockSubmitAndTest(false);
          Navigate(-1);
        }
      })
    }
    else {
      setDisableSubmit(false);
      setBlockSubmitAndTest(false);
    }
  }

  useEffect(() => {
    console.log(isRevert);
    if (isRevert == "true" || isRevert == true) {
      console.log(isRevert);
      // SecuredServices.getProgramDetailsByQName(qName).then((res)=>{
      //     console.log(res.data.data);
      //     if(res.data.status==1){
      //         setUserInputData({
      //             program:res.data.data[0].program,
      //             rubrics:JSON.parse(res.data.data[0].rubrics)
      //         })
      //         // setUserInputData(res.data.data[0]);
      //     }
      // })
    }
  }, [isRevert])

  const validateJson = (data) => {
    try {
      console.log(data);
      JSON.parse(data);
      console.log(data);
      return true;
    }
    catch (err) {
      console.log(err)
      return false;
    }
  }

  const onMcqUploadSuccess=(imageUrl)=>{
    console.log(imageUrl);
    let mcqUserInpData = userInputData
    let mcqJson = JSON.parse(mcqUserInpData[selectedQnameIndex].mcq_json);
    mcqJson.question_image = imageUrl;
    console.log(mcqJson);
    mcqUserInpData[selectedQnameIndex].mcq_json =JSON.stringify(mcqJson);
    console.log(mcqUserInpData);
    setUserInputData(mcqUserInpData);

    setShouldUploadMcqImage(false);
  }


  const checkIfQuestionImageIsPresent = (jsonData)=>{
    try {
       let data =  JSON.parse(jsonData);
       if(data?.question_image == true || data?.question_image?.length>5){
        return true
       }
       return false;

    } catch (error) {
        console.log(error);

    }
  }
 
  return (
    <SubmitMcqProgramWrapper>
      <Breadcrumb style={{ margin: '16px 0' }}>
        <Breadcrumb.Item onClick={() => { window.history.back() }}>Create Content</Breadcrumb.Item>
        <Breadcrumb.Item>{qName}</Breadcrumb.Item>
      </Breadcrumb>

      <h3>Submit Mcq</h3>


      <div className='formParent'>
        <div>
          <h2>Submit Mcq JSON</h2>
        </div>

        <div className='submitExactform' style={{ display: "block" }}>
          <label htmlFor="" className='label'>Topic:</label>
          {
            topicDisabled ? <div className='topicName'>
              <p className='topicName'>{selectedTopic.topic_name}</p>
              <p className='changeTopicButton' onClick={() => setTopicDisabled(false)}>change topic</p>

            </div> :
              <Select style={{ width: "100%" }} defaultValue={selectedTopic.topic_name} value={selectedTopic.topic_name} disabled={topicDisabled} onChange={handleTopicChange} >
                <Select.Option value={-1} key={-1}>Select Topic</Select.Option>
                {
                  topics.map((topic) => {
                    return <Select.Option value={topic.topic_id} key={topic.topic_id}>{topic.name}</Select.Option>
                  })
                }
              </Select>
          }

        </div>


        <div className='form'>
          {
            userInputData?.map((value, index) => {
              console.log(value);
              return <div className='formElement'>

                <label htmlFor="" className='label1'>Mcq {index + 1}:</label>
{/* 
                <div className="selectConcept">
                  <label htmlFor="" className="label">Concept:</label>
                  <Select
                    placeholder="Select Concept"
                    style={{ width: "100%" }}
                    value={value?.concept_id || -1} // Ensure value is always defined
                    onChange={(selectedValue) => {
                      const selectedConcept = concepts.find(concept => concept.concept_id === selectedValue);
                      setUserInputData({
                        ...userInputData,
                        concept_id: selectedValue,
                        concept_name: selectedConcept?.name,
                      });
                    }}
                  >
                    <Select.Option value={-1} key={-1}>Select Concept</Select.Option>
                    {concepts.map((concept) => (
                      <Select.Option value={concept.concept_id} key={concept.concept_id}>
                        {concept.concept_name}
                      </Select.Option>
                    ))}
                  </Select>
                </div> */}



                <Input.TextArea rows={6}
                  placeholder='Input Mcq Json'
                  value={value.mcq_json}
                  name=""
                  onChange={(e) => {
                    try {
                      // Parse the updated JSONonsole.log(console.log(e.target.value));
                      console.log(e.target.value);
                      const updatedJson = e.target.value;

                      // Create a new copy of userInputData
                      let mcqJson = { ...value }
                      mcqJson.mcq_json = updatedJson;
                      let data = [...userInputData];
                      data[index] = mcqJson;
                      // Set the new state
                      setUserInputData(data);
                    } catch (error) {
                      console.error("Invalid JSON input:", error);
                      throw error;
                    }
                  }}
                />
                {
                  checkIfQuestionImageIsPresent(value.mcq_json)==true? 
                  <div className='uploadImage'>
                      
                    <p>This Question Contains Image. Create and upload the Image. <a href onClick={()=>{setOriginalImagesPopup(true)}}>View original images</a></p>
                    <Button type='primary' onClick={() => {
                      let mcqJs= JSON.parse(value.mcq_json);
                      console.log(mcqJs.q_name);
                      setSelectedQName(mcqJs.q_name);
                      setSelectedQnameIndex(index);
                      setShouldUploadMcqImage(true);
                     }}>{ checkIfQuestionImageIsPresent(value.mcq_json)==true? "Modify Image":"Upload Image"}</Button>
                    </div> : <></>

                }

              </div>
            })
          }







          <div className='saveAndTestButton' onClick={() => handleSubmit(false)}>
            <button>Test </button>
          </div>


          <div className='buttonsSections'>
            <div className='submitButton' onClick={() => handleSubmit(true)} style={{ pointerEvents: disableSubmit ? "none" : "auto", opacity: disableSubmit ? 0.5 : 1 }}>
              {blockSubmitAndTest ? <div style={{ display: "flex", alignItems: "center", gap: "5px" }}><img src={Spinner} width={20} />processing</div> : "Submit"}
            </div>
          </div>

        </div>
      </div>

      {/* <GenricModal
            closable={true}
            width={700}
            title={"Verify Mission Program"}
            isOpen={missionPdfModal}
            toggle={()=>setMissionPdfModal(false) }
        /> */}
      <GenricModal
        closable={true}
        width={900}
        data={userInputData}
        title={"Test Mcq Questions"}
        isOpen={testMcqModal}
        toggle={() => setTestMcqModal(false)}
      />

      <GenricModal
        closable={true}
        width={900}
        data={selectedQname}
        title={"Upload MCQ Images"}
        isOpen={shouldUploadMcqImage}
        toggle={() => setShouldUploadMcqImage(false)}
        onSubmitClick={onMcqUploadSuccess}
      />

      <GenricModal 
        title="Question Details" 
        isOpen={orignalImagesPopup} 
        toggle={()=>setOriginalImagesPopup(false)}
        width={900}
        data={{attachments: attachments.attachments,shouldShowSubmitButton:false}}
        onSubmitClick={()=>{}}
         />

    </SubmitMcqProgramWrapper>
  )
}
