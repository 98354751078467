import React, { useEffect ,useState} from 'react';
import { SubmitExactWrapper } from './SubmitExact.style';
import { Breadcrumb, Input, Progress, Select,Table,message } from 'antd';
import { useLocation, } from 'react-router-dom';
import SecuredServices from 'api/securedServices';
import deleteIcon from "assets/images/deleteIcon.svg";
import { IqLink } from 'helper/constants';
import Spinner from "assets/images/Spinner.gif";
import GenricModal from 'components/comman/modalComponents/modal/modal';



export default function SubmitExact() {

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const qName = params.get('q_name');
    const topic_id= params.get('topic_id');
    const grade_id= params.get('grade');
    const topic_name= params.get('topic');
    const creator_queue_id = params.get("creator_queue_id");
    let q_id = params.get('q_id');
    const [qId,setQId] = useState(q_id);
    const type = params.get('type');
    const token = params.get('token');
   const [userInputData,setUserInputData] = useState({
       topic_id:topic_id?parseInt(topic_id):-1,
       topic_name:topic_name?topic_name:"",
       concept_id:-1,
       concept_name:"",
       q_tex:null,
       a_tex:null,
       iq_annotation:null,
   })
   const [concepts,setConcepts] = useState([]);
   const [topics,setTopics] = useState([]);
   const [topicDisabled,setTopicDisabled] = useState(true);
   const [disableSubmit, setDisableSubmit] = useState(false);
   const [previews,setPreviews] = useState([]);
   const [file,setFile] = useState(null);
   const [currentStep,setCurrentStep] = useState(1);
   const [showImagesAndIds,setShowImagesAndIds] = useState(true);
   const [ImagesAndIdsData,setImagesAndIdsData] = useState([]);
   const [blockSubmitAndTest,setBlockSubmitAndTest] = useState(false);
   const [disablePrev,setDisablePrev] = useState(false);
   const [showTextModal,setShowTextModal] = useState(false);




   useEffect(()=>{

    if(currentStep==1){
        return;
    }

    SecuredServices.getTopics(grade_id,1).then((res)=>{
        console.log(res.data.data);
        let topics = res.data.data.data;
        setTopics(topics);
    })
},[currentStep])


const updateQIdParam = (newQId) => {
    const searchParams = new URLSearchParams(location.search);


      searchParams.set('q_id', newQId);


    // Update the URL without reloading the page or navigating
    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newUrl);
  };

useEffect(()=>{

    if(showImagesAndIds){
        let id = q_id!=null && q_id!="null"?q_id:creator_queue_id;
        console.log(id);
        let isQid = q_id!=null && q_id!="null"?true:false;
        SecuredServices.getSubmittedExactImages(qName,id,isQid).then((res)=>{
            console.log(res.data.data);
            if(res.data.status==1){
                if(res.data.data.length!=0){
                    setImagesAndIdsData(res.data.data);
                    setShowImagesAndIds(true);
                }
                else{
                    setShowImagesAndIds(false);
                }
            }
        })
    }
   
},[showImagesAndIds])

useEffect(()=>{
    if(q_id!="null" && q_id!=null){
        SecuredServices.getInstanceDetailsByQid(q_id).then((res)=>{
            console.log(res.data.data);
            if(res.data.status==1){
    
                setUserInputData(res.data.data[0]);
                if(res.data.data[0].auto_generated!=0){
                    setCurrentStep(2);
                    setShowImagesAndIds(false);
                    setDisablePrev(true);
                }
            }
        })
    }
},[q_id])


useEffect(()=>{
    if(userInputData.topic_id && userInputData.topic_id!=-1){
        SecuredServices.getConcepts(userInputData.topic_id).then((res)=>{
            console.log(res.data.data);
            setConcepts(res.data.data.data);
        })
    }
    
},[q_id])


   const handleSubmit = (isSubmit)=>{

        setBlockSubmitAndTest(true);
       setDisableSubmit(true);
       console.log(disableSubmit);
       console.log(userInputData);
      
       const searchParams = new URLSearchParams(location.search);
        q_id = searchParams.get('q_id');
        console.log(q_id);
        let data = userInputData;
        data.creator_queue_id = creator_queue_id;
        data.isSubmit = isSubmit;
        data.q_id = qId;
        let submitflag =1;
        data.q_name = qName;
        data.type = type;

        console.log(data);
       if(data.topic_id==-1 || data.concept_id==-1 || data.q_tex==null || data.a_tex==null || data.iq_annotation==null ||data.topic_name=="" || data.concept_name==""){
           submitflag = 0;
           message.error("Please fill all the required(*) fields");
           setDisableSubmit(false);
           setBlockSubmitAndTest(false);
       }
       console.log(data);

       let errors = validateIqAnnotation(data.iq_annotation);
       console.log(errors);
       if(errors.length > 0){
           submitflag = 0;
           message.error(errors.join('\n'));
           setDisableSubmit(false);
           setBlockSubmitAndTest(false);
       }
      
       if(submitflag==1){
            SecuredServices.submitExactFiles(data).then((res)=>{
                console.log(res.data);
                if(res.data.status==1){
                    if(isSubmit){
                        message.success("Question Submitted Successfully");
                        window.history.back();
                    }
                    else{
                        console.log(res.data.data.q_id);

                        q_id = res.data.data.q_id;
                        setQId(q_id);
                        updateQIdParam(res.data.data.q_id);
                        message.success("Question Saved Successfully launching the testing url");
                        window.open(`${IqLink}ip-verify/${token}/${topic_id}/${res.data.data.q_id}`,'_blank');
                    }
                    setBlockSubmitAndTest(false);
                    setDisableSubmit(false);
                  
                }
            }).catch((err)=>{
                console.log(err);
                message.error("Something went wrong"
                );
                setDisableSubmit(false);
                setBlockSubmitAndTest(false)
            }).finally(()=>{
                setDisableSubmit(false);
                setBlockSubmitAndTest(false)
            })
       }
       setDisableSubmit(false);
    
    }


    function validateIqAnnotation(lines) {
        // const insertPattern = /^<<step_insert(?:\s+<<verify=[a-zA-Z_][a-zA-Z0-9_]*>>)?>>$/;
        // const finalPattern = /^<<final(?:\s+<<verify=[a-zA-Z_][a-zA-Z0-9_]*>>)?>>$/;
        // const verifierPattern = /^<<verifier=[a-zA-Z_][a-zA-Z0-9_]*>>$/;
        const insertPattern = /^<<step_insert(?:\s+<<verify=[a-zA-Z_][a-zA-Z0-9_]*>>)?\s*>>/;
        const finalPattern = /^<<final(?:\s+<<verify=[a-zA-Z_][a-zA-Z0-9_]*>>)?\s*>>/;
        const imagePattern = /^<<image(?::\d+)?\s*>>/
        const verifierPattern = /^<<verifier=[a-zA-Z_][a-zA-Z0-9_]*>>$/;
        const errors = [];

        lines = lines.split('\n');
        console.log(lines);
    
        lines.forEach((line, index) => {
            const lineNumber = index + 1;
    
            if (line.startsWith('<<')) {
                // Check for proper closure
                if (!line.endsWith('>>')) {
                    errors.push(`Line ${lineNumber}: Missing closing '>>'`);
                    return;
                }
    
                // Validate insert lines
                if (line.startsWith('<<step_insert')) {
                    if (!insertPattern.test(line)) {
                        errors.push(`Line ${lineNumber}: Invalid <<insert>> format`);
                    }
                }

                // Validate final lines
                else if (line.startsWith('<<final')) {
                    if (!finalPattern.test(line)) {
                        errors.push(`Line ${lineNumber}: Invalid <<final>> format`);
                    }
                }
                 // Validate final lines
                 else if (line.startsWith('<<image')) {
                    if (!imagePattern.test(line)) {
                        errors.push(`Line ${lineNumber}: Invalid <<image>> format`);
                    }
                }

                 // Validate verifier directive
            else if (line.startsWith('<<verifier')) {
                if (!verifierPattern.test(line)) {
                    errors.push(`Line ${lineNumber}: Invalid <<verifier>> format`);
                }
            }
                // Any other lines starting with '<<'
                else {
                    errors.push(`Line ${lineNumber}: Unknown directive`);
                }
            }
        });
    
        return errors;
    }
    const handleTopicChange = (value)=>{
        setUserInputData({...userInputData,topic_id:value,topic_name:topics.find(topic=>topic.topic_id===value)?.name,concept_id:-1,concept_name:""});
    }

    const handleImage = (e)=>{
        const files = Array.from(event.target.files);
        console.log(e.target.files);
        setFile(files);

        const newPreviews = files.map(file => {
            return {
                file: file,
                preview:URL.createObjectURL(file)}
          });
          let newPreviews2 = [...previews,...newPreviews];
          setPreviews(newPreviews2);
    }

    const removeImage= (index)=>{
        let newPreviews = [...previews];

        newPreviews.splice(index,1);
        setPreviews(newPreviews);
    }

    const handleSubmitImages = ()=>{

        setDisableSubmit(true);

        let data = new FormData();
        data.append("q_name",qName);
        data.append("type",type=="iq"||type=="mission"?1:2);
        console.log(q_id);
        if(q_id !=null && q_id !="null"){
            data.append("creator_queue_id",q_id);
            data.append("isQid",true);
        }
        else{
            console.log("going inside else");
            data.append("creator_queue_id",creator_queue_id);
            data.append("isQid",false);

        }
        if(previews.length==0){
            message.error("Please add atleast one image");
            setDisableSubmit(false);
            return;
        }
        previews.forEach((preview)=>{
            data.append("files",preview.file);
        })
            SecuredServices.submitExactImagesAndGenerateId(data).then((res)=>{
                console.log(res.data);
                if(res.data.status==1){
                    message.success("Question Submitted Successfully");
                    setImagesAndIdsData(res.data.data);
                    setPreviews([]);
                    setShowImagesAndIds(true);
                    setDisableSubmit(false);
                }
            })
       
    }
    const handleClickOnRemoveImage =(id)=>{
        const qid = q_id!=null && q_id !="null"?q_id:creator_queue_id;
        const isQid = q_id !=null && q_id !="null"?true:false;
        SecuredServices.removeExactImage(id,qName,qid,isQid).then((res)=>{
            console.log(res.data);
            if(res.data.status==1){
                message.success("Image Removed Successfully");
                if(res.data.data.length!=0){
                    setImagesAndIdsData(res.data.data);
                    setShowImagesAndIds(true);
                }
                else{
                    setShowImagesAndIds(false);
                }
            
            }
        })
    }

    const columns= [
        {
            title:"generated id",
            dataIndex:"attachment_id",
            key:"attachment_id"
        },
        {
            title:"image",
            key:"image",
            render:(text,record)=>{
                return <img src={record.url} alt="" style={{width:"100px",maxHeight:"200px"}} />
            }
        },
        {
            title:"action",
            key:"action",
            render:(text,record)=>{
                return <button className='removeButton' onClick={()=>handleClickOnRemoveImage(record.id)}>Remove</button>
            }
        }
    ]

  return (
    <SubmitExactWrapper>
        <Breadcrumb style={{ margin: '16px 0' }}>
          <Breadcrumb.Item onClick={()=>{window.history.back()}}>Create Content</Breadcrumb.Item>
          <Breadcrumb.Item>{qName}</Breadcrumb.Item>
         </Breadcrumb>

         <div>

            <h3>Submit Files</h3>
         </div>

        <div className='submitExactformParent' >
            <div className='step1Form' style={{display:currentStep===1 && !showImagesAndIds?"block":"none"}}>
                <p>Step 1 of 2</p>
                <p className='submitImagesText'>Submit Images and Generate Id's</p>
                <div className='uploadFormElement'>
                    <label htmlFor=""  className='label'>*Image(s):</label>
                    <input type='file'
                     maxLength={10}
                     accept="image/png ,image/jpeg ,image/jpg"
                     multiple="multiple" onChange={handleImage} />
                </div>

                <div className='preview-images'>
                    {
                        previews.map((imgObj,index)=>{
                            return <div className='preview'>
                                    <img className='deleteIcon' src={deleteIcon} width={20}  onClick={()=>removeImage(index)} alt="" />
                                  <img src={imgObj.preview} width={"100px"} key={index} />
                                </div>
                          
                        })
                    }
                </div>
                <div className='buttonsSections'>
                <div className='skipButton' onClick={()=>{
                    setShowImagesAndIds(false);
                    setCurrentStep(2)}}>
                    <p>Skip</p>
                </div>
                <div className='submitButton' onClick={handleSubmitImages} style={{pointerEvents: disableSubmit?"none":"auto",opacity: disableSubmit?0.5:1}}>
                    <p>Submit and Generate</p>
                </div>
                </div>
              
            </div>

            <div className='showSelectedImagesAndIds' style={{display:showImagesAndIds?"block":"none"}}>
                <div className='submitImagesTextParent'>
                <p>Step 1 of 2</p>
            <p className='submitImagesText'>Show Selected Images and Id's</p>
                </div>
           

                    <Table columns={columns} dataSource={ImagesAndIdsData} />

                    <div className='buttonsSections'>
                    <div className='addMoreImagesButton' onClick={()=>{
                        setShowImagesAndIds(false);
                    }} style={{pointerEvents: disableSubmit?"none":"auto",opacity: disableSubmit?0.5:1}}>
                    <p>Add More Images</p>
                    </div>
                    <div className='nextButton' onClick={()=>{
                        setShowImagesAndIds(false);
                        setCurrentStep(2)}} style={{pointerEvents: disableSubmit?"none":"auto",opacity: disableSubmit?0.5:1}}>
                    <p>Next</p>
                </div>
                    </div>
            </div>
            <div className='step2Form' style={{display:currentStep===2?"block":"none"}}>
            <div className='submitImagesTextParent'>
                <p>Step 2 of 2</p>
            <p className='submitImagesText'>Enter Details and Submit</p>
                </div>
            <div className='submitExactform' style={{display:currentStep===2?"block":"none"}}>
                <label htmlFor="" className='label'>Topic:</label>
                {
                    topicDisabled? <div className='topicName'>
                     <p className='topicName'>{topic_name}</p>
                     <p className='changeTopicButton' onClick={()=>setTopicDisabled(false)}>change topic</p>

                     </div>: 
                     <Select  style={{width:"100%"}} defaultValue={userInputData.topic_id} value={userInputData.topic_id} disabled={topicDisabled} onChange={handleTopicChange} >
                    <Select.Option value={-1} key={-1}>Select Topic</Select.Option>
                           {
                            topics.map((topic)=>{
                                   return <Select.Option value={topic.topic_id} key={topic.topic_id}>{topic.name}</Select.Option>
                               })
                           }
                        </Select>
                }
              
            </div>
            <div>
                <label htmlFor="" className='label'>Concept:</label>
                <Select defaultValue={-1} style={{width:"100%"}} value={userInputData.concept_id} onChange={(value)=>setUserInputData({...userInputData,concept_id:value,concept_name:concepts.find(concept=>concept.concept_id===value)?.name})} >
                <Select.Option value={-1} key={-1}>Select Concept</Select.Option>
                       {
                        concepts.map((concept)=>{
                               return <Select.Option value={concept.concept_id} key={concept.concept_id}>{concept.name}</Select.Option>
                           })
                       }
                    </Select>
            </div>
            <div className='submitExactform'>
                <label htmlFor="" className='label'>Cleaned Q-Tex:</label>
                <Input.TextArea rows={3}
                placeholder='Enter your Q-Tex here'
                value={userInputData.q_tex}
                name="" 
                onChange={(e)=>setUserInputData({...userInputData,q_tex:e.target.value})}
                 />
            </div>

            <div className='submitExactform'>
                <label htmlFor="" className='label'>Cleaned A-Tex:</label>
                <Input.TextArea
                rows={3}
                value={userInputData.a_tex}
                placeholder='Enter your A-Tex here'
                name=""
                  onChange={(e)=>setUserInputData({...userInputData,a_tex:e.target.value})}
                    />
            </div>

            <div className="submitExactform">
                <label htmlFor="" className='label'>Original Reference Annotation (use it as a reference for your annotation):</label>
                <Input.TextArea 
                disabled
                onCopy={(e)=>{
                    e.preventDefault();
                    message.warning("Copy is not allowed")
                }}
                style={{background:"#e3dede",color:"gray",cursor:"not-allowed",userSelect:"none"}}
                value={userInputData?.original_iq_annotation}
                placeholder=''
                rows={5}
                name=""

                // onChange={(e)=>setUserInputData({...userInputData,iq_annotation:e.target.value})}
                 />
            </div>

            <div className='submitExactform'>
               
                <label htmlFor="" className='label'>Annotated Tex:</label>
               
                <Input.TextArea 
                value={userInputData.iq_annotation}
                placeholder='Enter your Annotated Tex here'
                name=""
                rows={5}

                onChange={(e)=>setUserInputData({...userInputData,iq_annotation:e.target.value})}
                 />
            </div>
            <div className={blockSubmitAndTest?"saveAndTestButton block":'saveAndTestButton '} onClick={()=>handleSubmit(false)}>
                <button>{blockSubmitAndTest? <div><img src={Spinner} width={20}/> processing</div>: "Save and Test"} </button>
            </div>
            <div className='buttonsSections'>
            <div className='prevButton' onClick={()=>{
                setCurrentStep(1);
                setShowImagesAndIds(true);

            }} style={{pointerEvents: disableSubmit || disablePrev?"none":"auto",opacity: disableSubmit  || disablePrev ?0.5:1}}>
                    <p>Prev</p>
                </div>
            <div className='submitButton' onClick={()=>handleSubmit(true)} style={{pointerEvents: disableSubmit?"none":"auto",opacity: disableSubmit?0.5:1}}>
                    <p>Submit</p>
                </div>
            </div>
           
            </div>


           
        </div>

        {/* <GenricModal
        title={"Text"}
        width={700}
        data={{title:"Original Reference Annotation",text:userInputData.original_iq_annotation}}
        isOpen={showTextModal}
        toggle={()=>setShowTextModal(false)}
        /> */}
    </SubmitExactWrapper>
  
  )
}
