import styled from "styled-components";


export const UploadMcqImageWrapper = styled.div`
.submitImagesText{
    font-weight: 500;
    font-size: 18px;
    margin-top: 2% !important;
}

.uploadFormElement{
    margin: 4% 0%;
}

.preview{
    width: 150px;
    background: #ddd8d8;
    margin: 2%;
    max-height:250px;
    position: relative;
}

.preview-images{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.deleteIcon{
    position: absolute;
    top: -10px;
    right: -5px;
    cursor: pointer;
}
.submitImagesText{
    font-weight: 500;
    font-size: 18px;
    margin-top: 2% !important;
}

.buttonsSections{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    padding:0 5%;
    margin-top:10%;
}
.submitButton{
    width: 150px;
    display: flex;
    font-size: 11px;
    justify-content: center;
    align-items: center;
    height: 30px;
    background: #0074ff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
`;