import React,{useState} from 'react'
import { UploadMcqImageWrapper } from './UploadMcqImageWrapper.style';
import deleteIcon from "assets/images/deleteIcon.svg";
import { message } from 'antd';
import SecuredServices from 'api/securedServices';

export default function UploadMcqImage({data,onSubmitClick}) {

  const handleSubmitImages = ()=>{
    let imagedata = new FormData();
    if(!file){
      message.warning("no image selected");
    }
    imagedata.append('q_name',data);
    previews.forEach((preview)=>{
      imagedata.append("files",preview.file);
  })
    console.log(file,data);
     SecuredServices.postUploadMcqImages(imagedata).then((res)=>{
      if(res.data.status==1){
        console.log(res);
        let fileLocation = res.data.data.location[0].location;
        message.success("Mcq Image Uploaded Successfully");
        onSubmitClick(fileLocation);
      }
    
     })
  }
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [previews,setPreviews] = useState([]);
  const [file,setFile] = useState();

  const handleImage = (e)=>{
    const files = Array.from(event.target.files);
    console.log(e.target.files);
    setFile(files);

    const newPreviews = files.map(file => {
        return {
            file: file,
            preview:URL.createObjectURL(file)}
      });
      let newPreviews2 = [...previews,...newPreviews];
      setPreviews(newPreviews2);
}

  const removeImage= (index)=>{
    let newPreviews = [...previews];

    newPreviews.splice(index,1);
    setPreviews(newPreviews);
}
  return (
    <UploadMcqImageWrapper>
                <p className='submitImagesText'>Submit Mcq Image</p>
                <div className='uploadFormElement'>
                    <label htmlFor=""  className='label'>*Image:</label>
                    <input type='file'
                     maxLength={1}
                     accept="image/png ,image/jpeg ,image/jpg"
                     onChange={handleImage} />
                </div>

                <div className='preview-images'>
                    {
                        previews?.map((imgObj,index)=>{
                            return <div className='preview'>
                                    <img className='deleteIcon' src={deleteIcon} width={20}  onClick={()=>removeImage(index)} alt="" />
                                  <img src={imgObj.preview} width={"100px"} key={index} />
                                </div>
                          
                        })
                    }
                </div>
                <div className='buttonsSections'>
                <div className='submitButton' onClick={handleSubmitImages} style={{pointerEvents: disableSubmit?"none":"auto",opacity: disableSubmit?0.5:1}}>
                    <p>Submit Image</p>
                </div>
                </div>
    </UploadMcqImageWrapper>
  )
}
